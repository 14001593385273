import React, {useContext} from 'react';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import classes from './electrical-safety.module.css';

const ElectricalSafety: React.FC = () => {
  const {theme, fontSize} = useContext(GlobalStateContext);

  return (
    <Layout>
      <SEO title="Електробезпека" />
      <Container className={clsx(classes.safety, theme !== 'regular' && classes.big_text)}>
        <PageTitle title={'Електро\u00adбезпека'} />
        <section className={classes.section}>
          {theme == 'regular' && (
            <div className={clsx(classes.img_container, classes.top)}>
              <img className={classes.img} src={'/electrical-safety/1.png'} alt="" />
            </div>
          )}
          <p>
            <b>Електробезпека</b> – система організаційних та технічних заходів і засобів, що забезпечують захист людей
            від шкідливого та небезпечного впливу електричного струму, електричної дуги, електромагнітного поля і
            статичної електрики.
          </p>
          <p>
            <strong>ПАМ'ЯТАЙМО</strong> – там, де присутній електричний струм - існує смертельна небезпека для життя
            людини!
          </p>
          <p>
            Усі електричні мережі, особливо високовольтні лінії електропередачі, мають державне значення та знаходяться
            під охороною.
          </p>
          <p>
            Щоб попередити нещасні випадки, забезпечити надійне електропостачання та якісну експлуатацію мереж, Кабінет
            Міністрів України затвердив&nbsp;
            <a href="https://zakon.rada.gov.ua/laws/show/209-97-%D0%BF#Text">Правила охорони електричних мереж</a>, в
            яких визначено:
            <ul>
              <li>перелік об'єктів, що підлягають охороні; </li>
              <li>заходи зі збереження мереж та умови їх експлуатації;</li>
              <li>правила безпеки тощо.</li>
            </ul>
          </p>
          <p>
            Повідомити про загрозу електробезпеці терміново потрібно оператору системи розподілу. На території
            Тернопільської області це може бути:
            <ul>
              <li>
                АТ «<a href="https://www.toe.com.ua/">Тернопільобленерго</a>», 0-800-50-90-40
              </li>
              <li>
                АТ «<a href="https://oe.if.ua/">Прикарпаттяобленерго</a>», 0-800-50-40-20, (0342) 59-40-20
              </li>
              <li>
                АТ «<a href="https://www.uz.gov.ua/about/activity/electropostachannia/">Укрзалізниця</a>»,
                099-24-41-127, 59-23-67
              </li>
              <li>
                АТ «<a href="http://loe.lviv.ua/ua/">Львівобленерго</a>», 0-800-30-15-68
              </li>
            </ul>
          </p>
        </section>

        <section className={classes.contacts}>
          <h2 className={clsx(classes.title, classes.contacts_title, theme !== 'regular' && classes.big_title)}>
            Контактні телефони РЕМ, по яких цілодобово приймають звернення споживачів:
          </h2>
          <ul className={clsx(classes.contacts_list, theme !== 'regular' && classes.columns)}>
            <li>
              <b>Бережанський район</b> 2-13-06
            </li>
            <li>
              <b>Борщівський район</b> 2-13-05
            </li>
            <li>
              <b>Бучацький район</b> 2-14-33
            </li>
            <li>
              <b>Гусятинський район</b> 2-11-74
            </li>
            <li>
              <b>Заліщицький район</b> 2-13-86
            </li>
            <li>
              <b>Збаразький район</b> 2-14-79
            </li>
            <li>
              <b>Зборівський район</b> 2-12-07
            </li>
            <li>
              <b>Козівський район</b> 2-17-44
            </li>
            <li>
              <b>Кременецький район</b> 2-27-70
            </li>
            <li>
              <b>Ланівецький район</b> 2-14-72
            </li>
            <li>
              <b>Монастирський район</b> 2-14-57
            </li>
            <li>
              <b>Підволочиський район</b> 2-10-48
            </li>
            <li>
              <b>Підгаєцький район</b> 2-16-06
            </li>
            <li>
              <b>Тернопільський район</b> 52-75-81
            </li>
            <li>
              <b>Тернопіль місто</b> 52-24-66
            </li>
            <li>
              <b>Теребовлянський район</b> 2-13-35
            </li>
            <li>
              <b>Чортківський район</b> 2-61-88
            </li>
            <li>
              <b>Шумський район</b> 2-14-03
            </li>
          </ul>
        </section>

        <section className={classes.section}>
          {theme == 'regular' && (
            <div className={clsx(classes.img_container, classes.middle)}>
              <img className={classes.img} src={'/electrical-safety/2.png'} alt="" />
            </div>
          )}

          <h2 className={classes.remember_title}>ЩОБ УНИКНУТИ НЕЩАСНОГО ВИПАДКУ ПОТРІБНО ПАМ’ЯТАТИ!</h2>
          <ul>
            <li>Не можна залишати електроприлади на мокрій поверхні та доторкатися до них мокрими руками.</li>
            <li>
              Не майте звички ставити на телевізор або ж поблизу комп'ютера вази з квітами, чашку з чаєм чи кавою,
              склянку з водою.
            </li>
            <li>
              Не допускайте потрапляння води на електроприлади, особливо якщо вони під'єднані до мережі. Якщо вилка
              шнура виявилася мокрою - не вмикайте її в розетку, а ретельно витріть. Інакше може статися коротке
              замикання, прилад вийде з ладу, а вас уразить струмом.
            </li>
            <li>
              Електрична енергія виробляється на атомних, теплових, водяних та інших електростанціях, а при допомозі
              трансформаторних підстанцій та електричних мереж передається до наших міст, сіл та осель. Тому всі
              трансформа­торні підстанції та лінії електропередавання перебувають в постійній роботі, а отже, приховують
              в собі смертельну небез­пеку для тих, хто до них наближається на недопустиму від­даль або торкається
              струмопровідних частин.
            </li>
            <li>
              Своєрідним «слабким місцем» більшості побутових електроприладів є мережевий шнур. Через часті згинання з
              часом можливий надлом або обрив струмоведучих жил, що веде до іскріння, нагрівання і, можливо, займання
              ізоляції проводу. Найчастіше всього таке пошкодження виникає в місці кріплення шнура до вилки.
            </li>
          </ul>
        </section>

        <section className={classes.section}>
          <h2 className={clsx(classes.title, theme !== 'regular' && classes.big_title)}>ТЕХНІКА БЕЗПЕКИ НА ВУЛИЦІ</h2>

          {theme == 'regular' && (
            <div className={clsx(classes.img_container, classes.bottom)}>
              <img className={classes.img} src={'/electrical-safety/3.png'} alt="" />
            </div>
          )}

          <ul>
            <li>
              Не можна вилізати на дахи будинків, де є електричні проводи, підніматися на опори ліній
              електропередавання, розводити вогнища під електролініями, закидати на проводи проволоку та інші предмети,
              торкатись до спусків заземлення опор.
            </li>
            <li>
              Повітряні лінії електропередавання змонтовані просто неба. А тому можуть у будь-яку хвилину бути
              пошкоджені вітром, наїздом транспорту, м'ячем, падінням дерев тощо.
            </li>

            <li>
              Заборонено рибалити, розпалювати вогнища, спалювати суху траву, вилазити на дерева поблизу повітряних
              ліній електропередачі та трансформаторних підстанцій, діставати будь-які предмети, що застрягли між
              проводами повітряних ліній.
            </li>

            <li>
              Заборонено самостійне зрізання гілок та дерев, які під час падіння можуть спричинити обриви проводів
              повітряних ліній.
            </li>

            <li>
              Забороняється проїзд під проводами повітряних ліній електропередавання машин,механізмів загальною висотою
              з вантажем або без нього від поверхні дороги понад 4,5 метра.
            </li>

            <li>Заборонено проводити земляні роботи в охоронних зонах кабельних ліній.</li>

            <li>
              Переїзди під проводами діючої повітряної лінії необхідно здійснювати в місцях найменшого провисання
              проводів (ближче до опори).
            </li>

            <li>
              Аварія на лінії електропередавання - це порушення нормального ритму життя та здоров'я людей, які в той
              момент перебувають на вулиці, неподалік від місця пригоди. Ситуація не проста, а небезпечна!
            </li>
          </ul>

          <p className={classes.info}>
            <b>Знайте, що:</b>
          </p>
          <p>
            Смертельно небезпечно не лишень торкатися, але й підходи­ти ближче, аніж на 8 метрів до обірваного проводу
            повітряної лінії, який лежить на землі чи звисає з опори! Необхідно негайно повідомити про це АТ
            «Тернопільобленерго» за номером телефону 0 800 50 90 40 виставити охорону чи огорожу, аби ніхто з людей не
            потрапив у небезпеку.
          </p>
          <p>
            Якщо ви випадково опинилися в такій небезпечній зоні розтікання Струму, то знайте, що не маючи засобів
            захисту (наприклад, гумового взуття), необхідно виходити з цієї зони повільно, пересуваючи ступні ніг по
            землі так, щоб вони не відривалися одна від одної.
          </p>
          <p>
            Організовувати ігри з волейболу та футболу поблизу чи під повітряними лініями електропередавання є дуже
            небезпечно!
          </p>
          <p>
            Заборонено рибалити, запускати повітряних зміїв, розпалювати вогнища, вилазити на дерева поблизу повітряних
            ліній електропередачі та електрообладнання, діставати будь-які предмети, що застрягли між проводами.
          </p>
          <p>
            Звер­таємося до батьків та дітей з проханням при користуванні електричною енергією пам'ятати, що переважна
            більшість нещасних випадків з людьми трапляється при самовільному проникненні у приміщення трансформаторних
            підстанцій та наближенні до струмопровідних частин на недопустиму віддаль. Найчастіше це робиться з метою
            крадіжки кольорового металу, при обривах проводів, при дитячих іграх поблизу ТП та ПЛ (волейбол, футбол)
            тощо.
          </p>
          <p>
            Щоб цього не відбулося, треба переконливо знати, що всі трансформаторні підстанції та лінії
            електропередавання цілодобово перебувають під напругою, а отже, по них протікає електричний Струм, який є
            смертельно небезпечним для життя і здоров'я людини.
          </p>
          <p>
            <strong>Також необхідно чітко діяти при виклику швидкої медичної допомоги.</strong> Наприклад так:
            «Терміново потрібна допомога. На вулиці (назвати вулицю і конкретне місце) або у селі трапився нещасний
            випадок. Сказати чітко, що сталося (обрив проводу, пожежа, авто­мобільна аварія тощо). Постраждала людина
            (стать, приблизно вік, вид травми). Запишіть, будь ласка, адресу (вулицю, номер будинку, квартири
            потерпілого)». І обов'язково переконайтесь в тому, що вас зрозуміли правильно.
          </p>
        </section>
      </Container>

      <section className={clsx(classes.emergency_contacts, theme !== 'regular' && classes.black_and_white)}>
        <strong>УВАГА!!!</strong>
        <p>Куди телефонувати при виявленні надзвичайної ситуації</p>
        <ul>
          <li>
            Кол-центр АТ «Тернопільобленерго» – <b>0 800 50 90 40</b>
          </li>
          <li>
            Швидка медична допомога – <b>103</b>
          </li>
          <li>
            Служба порятунку – <b>101</b>
          </li>
          <li>
            Поліція – <b>102</b>
          </li>
        </ul>
      </section>
    </Layout>
  );
};

export default ElectricalSafety;
